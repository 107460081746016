.customMetaDataItem {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid gray;
  height: 32px;
}

.customMetaDataItem_key {
  display: flex;
  font-weight: bold;
  align-items: center;
}

.customMetaDataItem_value {
  text-align: end;
}

.changeDatasetNameButton,
.confirmDatasetNameButton {
  border: none;
  outline: none;
  background-color: transparent;
}

.datasetNameChangeInputArea {
  height: 5px;
  align-content: center;
  width: auto;
}

.datasetNameChangeInput {
  background-color: transparent !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.editButton {
  display: flex;
  justify-content: end;
  bottom: 8px;
  right: 0px;
}
