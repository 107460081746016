@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@import url('../src/Styles/Modal.css');

html {
  font-size: 90%;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.login {
  text-align: center;
  margin: 10% auto auto;
}

.input-group {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 0;
}

.octicon-trashcan {
  transition: fill 0.15s ease-in-out;
}

.octicon-book {
  transition: fill 0.15s ease-in-out;
}

.octicon-clippy {
  transition: fill 0.15s ease-in-out;
}

.octicon-check {
  transition: fill 0.15s ease-in-out;
}

a .octicon-trashcan:hover {
  fill: red;
  transition: fill 0.15s ease-in-out;
}

.dataset-plot {
  height: 100%;
}

.btn-edit {
  float: right;
  max-width: 100px;
}

.btn-edit:hover {
  color: rgb(72, 187, 120) !important;
}

.dataset-plotband {
  z-index: 9;
  opacity: 0.8;
  fill-opacity: 0.3;
  cursor: pointer;
  transition:
    fill 0.15s,
    opacity 0.15s;
}

.dataset-plotband-selected {
  z-index: 10;
  opacity: 0.8;
  fill-opacity: 0.4;
  cursor: pointer;
  transition:
    fill 0.15s,
    opacity 0.15s;
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

.loader {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.navbar-brand {
  display: flex;
  align-items: center;
}
.navbar-brand > img {
  padding: 7px 14px;
}

.svg-white {
  fill: white;
}

.svg-red {
  fill: red;
}

.svg-green {
  fill: #28a745;
}

.svg-teal {
  fill: #17a2b8;
}

.svg-mirrored {
  transform: scale(-1, 1);
}

.button-toolbar {
  outline: none;
}

.button-toolbar-delete:hover:enabled .svg-red {
  fill: white;
}

.list-buttongroup button:hover:enabled .svg-teal {
  fill: white;
}

.save-buttongroup button:hover:enabled .svg-green {
  fill: white;
}

.button-invisible {
  opacity: 0;
}

.highcharts-credits {
  display: none;
}

.dataset-tooblar-button-selected {
  text-decoration: blink;
}

.dataset-tooblar-button {
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  box-shadow: none;
}

button {
  box-shadow: none;
}

.dataset-plotline {
  z-index: 9;
  transition:
    stroke 0.15s,
    opacity 0.15s,
    stroke-width 0.15s;
  opacity: 0.5;
}

.dataset-plotline-selected {
  z-index: 10;
  transition:
    stroke 0.15s,
    opacity 0.15s,
    stroke-width 0.15s;
  cursor: ew-resize;
  opacity: 0.75;
  stroke-width: 5px;
}

.list-buttongroup {
  height: 24px;
  display: flex;
}

.list-buttongroup button {
  margin-top: -7px;
  margin-bottom: -7px;
}

.list-tooltip {
  opacity: 0.5;
}

.tooltip-inner {
  font-size: 12px;
}

.save-button {
  transition: all 0.15s;
}

div.card {
  text-align: center;
  border: 0px transparent solid;
  border-radius: 0.25rem;
}

.labelings-column {
  vertical-align: middle !important;
}

.datasets-column {
  vertical-align: middle !important;
  word-wrap: break-word;
}

.datasets-check {
  position: relative;
  margin: 0;
}

.instruction-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.navbar-dropdown {
  position: inherit !important;
  right: 0 !important;
  padding: 0 !important;
}

.error-text {
  color: red;
}

.footer {
  background-color: #eeeeee;
  color: black !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

/* Highcart stuff */
.highcharts-plot-band {
  cursor: pointer;
}

.highcharts-plot-band:hover {
  opacity: 0.3;
}

.dataset-labelingpanel {
  position: fixed;
  margin-left: 160px;
  bottom: 15px;
  left: 15px;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dataset-snackbar-center {
  width: calc(75% + 15px);
  display: flex;
  justify-content: center;
}

.modal-header {
  justify-content: center;
}

.modal-title {
  font-size: x-large;
  font-weight: bold;
}

/* Nice spacing for tables */
.table-record > * {
  width: 33%;
  vertical-align: middle !important;
}

.table-record-left {
  text-align: left;
}

.table-record-right {
  text-align: right;
}

.table-record-center {
  text-align: center;
}

.hyperparameter-input-container {
  width: auto;
  height: auto;
  border-radius: 0px 4px 4px 0px;
}

.hyperparameter-input-container-multi {
  width: auto;
  height: auto;
}

.hyperparameter-input__control {
  border-radius: 0px 4px 4px 0px !important;
}

.ConfusionMatrix {
  border-collapse: collapse;
}

.ConfusionMatrix td {
  border: 1px solid #e4e4e4;
}

.ConfusionMatrixHeader td {
  height: 40px;
  white-space: nowrap;
  font-size: 10px;
  font-weight: bold;
}

.ConfusionMatrixRow {
  font-size: 10px;
}

/* .ConfusionMatrixRow:hover {
  background: yellow;
} */

.ConfusionMatrixLabel {
  font-size: 10px;
  text-align: right;
  font-weight: bold;
}

.ConfusionMatrixCell {
  text-align: center;
}

.ConfusionMatrixScale {
  background: #4a7d70;
  background-image: linear-gradient(
    90deg,
    #440154,
    #482475,
    #414487,
    #355f8d,
    #2a788e,
    #21908d,
    #22a884,
    #42be71,
    #7ad151,
    #bddf26,
    #bddf26
  );
}

.btn {
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.btn:hover,
.btn:focus,
.btn:active {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
  filter: brightness(95%);
}

.btn-secondary {
  background: rgb(226, 232, 240);
  color: black;
  border-color: rgb(226, 232, 240);
}

.btn-secondary:hover,
.btn-secondary:focus {
  background: rgb(226, 232, 240);
  color: black;
  border-color: rgb(226, 232, 240);
}

.btn-secondary:active {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-success {
  background-color: rgb(72, 187, 120);
  border-color: rgb(72, 187, 120);
}

.btn-success:hover {
  background-color: rgb(72, 187, 120);
  border-color: rgb(72, 187, 120);
}

.input-group-prepend > .input-group-text {
  background: #ced4da;
}

.btn-delete:hover {
  color: rgb(213, 63, 140);
}

.btn-neutral {
  background-color: white;
  border-color: rgb(230, 230, 234);
  color: rgb(34, 41, 51);
  padding: 10px 15px;
  border-radius: 6px;
  border-width: 2px;
}

.header-wrapper {
  background: rgb(249, 251, 252);
  border: 2px solid rgb(230, 230, 234);
  border-radius: 10px 10px 0px 0px;
  border-width: 2px 2px 0px 2px;
  padding: 10px;
}

.body-wrapper {
  border: 2px solid rgb(230, 230, 234);
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.body-wrapper-overflow {
  border: 2px solid rgb(230, 230, 234);
  border-radius: 0px 0px 10px 10px;
  overflow: visible;
}

.edgeml-border {
  border: 2 px solid rgb(230, 230, 234) !important;
  border-width: 2px !important;
  border-radius: 10px !important;
}

.edgeml-gray-one {
  background-color: #ddd;
}

.edgeml-fade-one {
  background: linear-gradient(rgb(26, 32, 44), rgb(45, 55, 72));
}

.edgeml-border {
  border: 2px solid rgb(230, 230, 234);
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

.hover-bigger {
  transition: transform 0.3s ease-in-out;
}

.hover-bigger:hover {
  transform: scale(1.01);
}

.child-gap > * {
  margin: 2px;
}
