.EditLabelingModal {
}

.clear::-ms-clear {
  display: none;
}

.tooltip > .tooltip-inner {
  font-size: 13px;
}

.deleteBtnRadius {
  border-radius: 0px 0.3rem 0.3rem 0px !important;
}

.edit-labeling-body .alert-danger {
  color: red;
  background-color: transparent;
  border: none;
  position: relative;
}
