.TimeSeriesCollectionPanel {
  overflow: scroll;
}

.timeInputGroupText {
  width: 70px;
}

.user-info-select-timeSeries {
  display: flex;
  justify-content: center;
  height: 66vh;
  align-items: center;
  font-weight: bold;
  font-size: larger;
}

/* .navigator {
  position: fixed;
  width: 90vw;
  z-index: 100;
  opacity: 0.85;
  margin-top: -5px;
  transform-origin: center;
} */

/* Apply scaling when viewport width is below 1920px */
@media (max-width: 1920px) {
  .navigator {
    transform: scaleX(0.9); /* Scale the width to 90% of its original width */
  }
}

@media (max-width: 960px) {
  .navigator {
    transform: scaleX(0.85);
  }
}

@media (max-width: 768px) {
  .navigator {
    transform: scaleX(1);
  }
}
