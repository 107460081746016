.mobile-header-base {
  height: 50px;
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mobile-header-navbar-fitting {
  width: 160px;
}
