.training_step {
  background-color: rgba(7, 52, 98, 0.6);
  color: black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: white;
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
  font-weight: bold;
  cursor: pointer;
}

.training_step > * {
  color: white;
}

.training_step_selected {
  background-color: rgba(7, 52, 98, 1);
}

.training_step_container {
  width: 150%;
}

.v_line {
  height: 20px;
  width: 20px;
  background-color: rgba(7, 52, 98, 1);
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  margin-bottom: -1px;
}

.borderTop {
  border-top: 2px solid rgba(7, 52, 98, 1);
}
