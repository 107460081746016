.LabelingSelectionPanel {
  width: 100%;
  position: sticky;
  z-index: 10;
  display: flex;
  justify-content: space-between;
}

/* mobile (md-breakpoint), account for 50px header with sticky */
@media (max-width: 768px) {
  .LabelingSelectionPanel {
    /* top: 65px; */
    position: relative;
    top: unset;
  }

  .managementBox {
    flex-basis: 100%;
    justify-content: space-between;
  }

  .labelingSetBox {
    flex-basis: 100%;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .managementBox {
    margin-left: auto;
  }
}

.LabelingSelectionButtonsContainer {
  float: left;
}

.bottom-line {
  border-bottom: 1px solid lightgray;
}

.dropdown > .button {
  font-size: 1rem;
}

.scrollable-dropdown {
  max-height: 50vh;
  max-width: 50vw;
  overflow-x: hidden;
  overflow-y: auto;
}
