.range-slider {
  height: 10px;
  position: relative;
  background-color: #e1e9f6;
  border-radius: 2px;
}

.range-selected {
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 5px;
  background-color: #1b53c0;
}

.range-input {
  position: relative;
}

.range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -7px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.range-input input::-webkit-slider-thumb {
  height: 30px;
  width: 5px;
  border-radius: 0.25rem;
  background-color: rgb(132, 62, 230);
  pointer-events: auto;
  -webkit-appearance: none;
}

.range-input input::-webkit-slider-thumb:hover {
  cursor: col-resize;
}

.range-price {
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range-price label {
  margin-right: 5px;
}

.range-price input {
  width: 40px;
  padding: 5px;
}

.range-price input:first-of-type {
  margin-right: 15px;
}

.range {
  margin: 32px;
}
