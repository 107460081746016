.tableHeadElement {
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.tableEntry {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.datasetCard {
  transition-duration: 0.3s;
}

.divider {
  border-bottom: 3px solid grey;
  width: 100%;
}

.dividerMetaData {
  border-bottom: 1px solid grey;
  width: 100%;
}

.animationDuration {
  transition-duration: 0.1s;
}

.collapse_arrow {
  transform: rotate(90deg);
}

.badgeSize {
  font-size: 0.8rem;
}

.badgeLabelings {
  color: black;
  background-color: unset;
  border: 2px rgb(131, 136, 159) solid;
  padding: 4px;
}

.labelingBadgeWrapper {
  position: relative;
  left: 0px;
  top: -10px;
  padding: 5px 5px;
  background: white;
  text-align: start;
  display: inline;
  color: rgb(131, 136, 159);
}

.showInfo {
  height: 0;
  overflow: hidden;
  -prefix-animation: slide 1s ease 3.5s forwards;
}

.btn-upload {
  padding: 2px 10px 2px 10px !important;
  margin-top: 12px !important;
  opacity: 0.95;
  border-radius: 20px;
}

@-prefix-keyframes slide {
  from {
    height: 0;
  }
  to {
    height: unset;
  }
}

.listCheckBox {
  width: 30px;
  align-self: stretch;
  background-color: red;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.data-upload-item {
  display: grid;
  grid-template-columns: 40px 1fr;
  justify-items: center;
}

.data-upload-item a {
  color: white !important;
  font-weight: 800;
}

.data-upload-icon {
  min-width: 40px;
  width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(72, 187, 120);
  border-radius: 20px;
  color: black;
  font-size: 15px;
  margin-right: 15px;
}

.data-upload-panel a {
  color: white;
  font-weight: 700;
}

.datasets-header-wrapper {
  background: rgb(249, 251, 252);
  border: 2px solid rgb(230, 230, 234);
  border-radius: 10px 10px 0px 0px;
  border-width: 2px 2px 0px 2px;
  padding: 10px;
}

.fixed-bottom {
  position: absolute;
  bottom: 0;
  padding: 20px;
}

@media (max-width: 768px) {
  .flex-wrap {
    flex-direction: column;
  }
}

.table-margin {
  margin-bottom: 80px;
}
