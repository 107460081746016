.selected {
  opacity: 0.4;
}

.deselected {
  opacity: 0.2;
}

.plotline {
  cursor: col-resize;
}

/* .dropdownWrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
}

.dropdownBtn {
  background-color: #f2f2f2;
  padding: 0 10px 1px 10px;
  border-radius: 15%;
  border: none;
}

.dropdownBtn:hover {
  background-color: #e6e6e6;
} */
/* 
.dropdownBtn:active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  filter: brightness(85%);
} */

.chartMenuWrapper {
  position: absolute;
  right: 8px;
  top: 8px;
  align-self: center;
  z-index: 5;
}

.chartBtn {
  background-color: #f2f2f2;
  padding: 0 6px 1px 6px;
  border-radius: 15%;
  border: none;
}

.chartBtn:hover {
  background-color: #e6e6e6;
}

.chartBtn:active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  filter: brightness(85%);
}

.scrollBtn {
  position: absolute;
  top: 43.5%;
  z-index: 5;
  position: absolute;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
  color: grey;
  font-family: Monaco, monospace;
  user-select: none;
}

.scrollBtn:hover {
  color: black;
}

.scrollBtn:active {
  font-size: 26px;
  filter: brightness(85%);
}

.scrollBtnLeft {
  left: 10px;
}

.scrollBtnRight {
  right: 10px;
}

.noDataLabel {
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #eeeeee;
  border-radius: 10px;
  margin-top: 8px;
}

.chartWrapper > div {
  overflow: unset !important;
}

.chartWrapper-height {
  height: 200px;
}
